.commentsTableContainer {
    display: flex;
    max-width: 90%;
    width: 90%;
    margin-left: 5%;
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgb(211, 208, 208);
    padding: 10px;
    justify-content: center;
}

.commentsContainer {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 20px;
    background-color: var(--siemens-off-bg);
    height: 100vh;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    padding-top: 20px;

}

.th {
    text-align: center !important;
}

.tr {
    text-align: center;
}

.tableHeader{
    text-align: center;
}

.tableHeaderRow{
    text-align: center;
}

.tablePagination{
    display: inline-flex;
    gap: 10px;
    align-items: center;
}

.tablePaginationPageNo {
    display: inline-flex;
}

.tablePaginationSelect {
    display: inline-flex;
}

.tablePaginationInput {
    display: inline-flex;
}