@import "../../resources/fonts/font.css";

.footer {
    margin-top: 5px;
    padding: 10px;
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid rgb(228, 222, 222);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
}

.footer-container {
    display: flex;
    padding: 0; 
    margin: 0;
    justify-content: space-between;
    width: 1220px;
    max-width: 1220px;
    min-width: 800px;
    align-items: center;
    /* background-color: aquamarine; */
}

.footer-copyright-container {
    padding: 0; 
    margin: 0;
    display: flex;
}

.footer-links-container {
    padding: 0; 
    margin: 0;
    display: flex;
    justify-content: space-evenly;
    gap: 100px;
    padding-right: 10px;
}

.footer-links-container p:hover {
    cursor: pointer;
    text-decoration: underline;
}

.footer-container > p {
    padding: 0; 
    margin: 0;
}

.footer-copyright-container > p {
    padding: 0; 
    margin: 0;
    font-family: "SiemensSans";
    /* font-family: Arial, Helvetica, sans-serif; */
    font-weight: bold;
}

.footer-links-container > a {
    padding: 0; 
    margin: 0;
    color: black;
    font-family: "SiemensSans";
}

.footer-links-container > a:link {
    text-decoration: none;
}

.footer-links-container > a:visited {
    text-decoration: none;
}

.footer-links-container > a:hover {
    text-decoration: underline;
}