@import "../../../resources/fonts/font.css";

.container {
    display: flex;
    padding: 5px 80px;
    justify-content: center;
    align-items: center;
    align-content: space-between;
    column-gap: 100px;
    flex-wrap: wrap;

    background: #FFF;
}

.siemensLogo{
    display: flex;
    padding: 10px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.links{
    display: flex;
    padding: 17px 0px;
    align-items: center;
    align-content: space-between;
    column-gap: 25px;
    /* flex-wrap: wrap; */
}

.link-styles{
    text-decoration: none;
    color: #099;
    text-align: center;
    /* Box Shadow Light */
    text-shadow: 0px 12px 18px 0px rgba(0, 0, 40, 0.10), 0px 4px 8px 0px rgba(0, 0, 40, 0.10), 0px 0px 2px 0px rgba(0, 0, 40, 0.10);
    font-size: 20px;
    font-family: "SiemensSans";
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: none;
    border: none;
    outline: none;
}

.link-styles:hover{
    color: rgb(15, 16, 16);
    cursor: pointer;
}

.amaLogo{
    display: flex;
    padding: 15px 1px;
    align-items: center;
    gap: 10px;
}