/* .AMAFormRow  {
    justify-content: center;
} */

label.AMAFormRowLabel {
    font-size: 17px;
    font-weight: bold;
    text-decoration: underline;
    padding: 5px;
    padding-left: 0;
    padding-bottom: 5px;
    padding-top: 20px;
}

.AMAFormButton {
    padding: 20px 0px;
}

input.input-error {
    border-color:red
}

textarea.input-error {
    border-color:red
}

label.input-error {
    border-color:red
}

p.error {
    color: red
}